import './App.css';
import { Component } from 'react';


function TTCCalcStep2Header() {
  return (
    <thead>
      <tr>
        <th colSpan="4">Thermal Test Chip Size</th>
        <th colSpan="7" className="leftSep">Thermal Test Chip Attributes</th>
      </tr>
      <tr>
        <th>TTC Version</th>
        <th>&nbsp;</th>
        <th># Cells</th>
        <th>Dimension (mm)</th>
        <th className="leftSep">Total Cells</th>
        <th># HR</th>
        <th># HR Contacts</th>
        <th># TSD</th>
        <th># TSD Contacts</th>
        <th># TSD Matrix Contacts</th>
        <th>Pdiss (W)</th>
      </tr>
    </thead>
  );
}

function TTCCalcStep2Row(props) {
  const { rowSpan, ttcVersion, xyLabel, changeCallback, dimension, totalCells, hrCount, hrContacts, tsd, tsdContacts, tsdMatrixContacts, pdiss } = props;

  let xID = ttcVersion === "TTC-1001" ? "xcells1001" : "xcells1002";
  let yID = ttcVersion === "TTC-1001" ? "ycells1001" : "ycells1002";
  if (rowSpan > 1) {
    return <tr>
            <td rowSpan={rowSpan}>{ttcVersion}</td>
            <td>{xyLabel}</td>
            <td><input id={xID} className="cellInput editableInput" onChange={changeCallback}/></td>
            <td>{dimension}</td>
            <td rowSpan={rowSpan} className="leftSep">{totalCells}</td>
            <td rowSpan={rowSpan}>{hrCount}</td>
            <td rowSpan={rowSpan}>{hrContacts}</td>
            <td rowSpan={rowSpan}>{tsd}</td>
            <td rowSpan={rowSpan}>{tsdContacts}</td>
            <td rowSpan={rowSpan}>{tsdMatrixContacts}</td>
            <td rowSpan={rowSpan}>{pdiss}</td>
           </tr>;
  } else {
    return <tr>
            <td>{xyLabel}</td>
            <td><input id={yID} className="cellInput editableInput" onChange={changeCallback}/></td>
            <td>{dimension}</td>

           </tr>;
  }
}

function TTCCalcStep2Body(props) {

  const rows = [];
  props.ttcData.forEach((element) => {
    let cellUnitSize = element.cellSize;
    if (!element.scribeLaneIncluded) {
      cellUnitSize += props.cellSpacing;
    }
    let xrowkey = "ttc_step2_rowx_" + cellUnitSize;
    let yrowkey = "ttc_step2_rowy_" + cellUnitSize;
    let ttc1001 = (element.ttcVersion === "TTC-1001");
    let callbackX = ttc1001 ? props.handle1XChange : props.handle2XChange;
    let callbackY = ttc1001 ? props.handle1YChange : props.handle2YChange;
    let cellCounts = ttc1001 ? props.cellCount1001 : props.cellCount1002;
    // row for x
    if (cellCounts.x > 0) {
      let xCellCount = cellCounts.x;
      let xDimension = xCellCount * cellUnitSize - props.cellSpacing;
      xDimension = xDimension.toFixed(3);
      let totalCells = null;
      if (cellCounts.x && cellCounts.y) {
        totalCells = cellCounts.x * cellCounts.y;
      }
      let hrCount = null;
      if (totalCells) {
        hrCount = ttc1001 ? totalCells : totalCells * 2;
      }
      let hrContacts = null;
      if (hrCount) {
        hrContacts = hrCount * 4;
      }
      let tsd = null;
      let tsdContacts = null;
      if (totalCells) {
        tsd = ttc1001 ? totalCells : totalCells * 4;
        tsdContacts = tsd * 2;
      }
      let tsdMatrixContacts = null;
      if (cellCounts.x && cellCounts.y) {
        tsdMatrixContacts = ttc1001 ? cellCounts.x + cellCounts.y : cellCounts.x*4 + cellCounts.y*8;
      }
      let pdiss=null;
      if (totalCells) {
        pdiss = ttc1001 ? totalCells * 3 : totalCells * 12;
      }
      
      rows.push(<TTCCalcStep2Row key={xrowkey}
                              rowSpan="2" 
                              xyLabel="X"
                              ttcVersion={element.ttcVersion} 
                              dimension={xDimension}
                              totalCells={totalCells}
                              hrCount={hrCount}
                              hrContacts={hrContacts}
                              tsd={tsd}
                              tsdContacts={tsdContacts}
                              tsdMatrixContacts={tsdMatrixContacts}
                              pdiss={pdiss}
                              changeCallback={callbackX}
                              />);
    } else {
      rows.push(<TTCCalcStep2Row key={xrowkey}
                              rowSpan="2" 
                              xyLabel="X"
                              ttcVersion={element.ttcVersion}
                              dimension="&nbsp;"
                              totalCells="&nbsp;"
                              hrCount="&nbsp;"
                              hrContacts="&nbsp;"
                              tsd="&nbsp;"
                              tsdContacts="&nbsp;"
                              tsdMatrixContacts="&nbsp;"
                              pdiss="&nbsp;"
                              changeCallback={callbackX} />);
    }
    // row for y      
    if (cellCounts.y > 0) {
      let yCellCount = cellCounts.y;
      let yDimension = yCellCount * cellUnitSize - props.cellSpacing;
      yDimension = yDimension.toFixed(3);
      
      rows.push(<TTCCalcStep2Row key={yrowkey}
                              rowSpan="1" 
                              xyLabel="Y"
                              ttcVersion={element.ttcVersion}
                              dimension={yDimension}
                              changeCallback={callbackY} />);
    } else {
      rows.push(<TTCCalcStep2Row key={yrowkey}
                              rowSpan="1" 
                              xyLabel="Y"
                              ttcVersion={element.ttcVersion}
                              dimension="&nbsp;"
                              changeCallback={callbackY} />);
    }
  });
  return <tbody>{rows}</tbody>;
}

function setInputsTriggerUpdate(x1, y1, x2, y2) {
  Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
  .set.call(document.getElementById("xcells1001"), x1);
  Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
    .set.call(document.getElementById("ycells1001"), y1);
  Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
    .set.call(document.getElementById("xcells1002"), x2);
  Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
    .set.call(document.getElementById("ycells1002"), y2);
  document.getElementById("xcells1001").dispatchEvent(new Event('change', { bubbles: true }));
  document.getElementById("ycells1001").dispatchEvent(new Event('change', { bubbles: true }));
  document.getElementById("xcells1002").dispatchEvent(new Event('change', { bubbles: true }));
  document.getElementById("ycells1002").dispatchEvent(new Event('change', { bubbles: true }));
}

function useOverSize() {
  // get the X/Y cell counts for the two TTC types and insert in our table
  let x1 = document.getElementById("TTC-1001_over_x").textContent;
  let y1 = document.getElementById("TTC-1001_over_y").textContent;
  let x2 = document.getElementById("TTC-1002_over_x").textContent;
  let y2 = document.getElementById("TTC-1002_over_y").textContent;
  setInputsTriggerUpdate(x1, y1, x2, y2);
}

function useUnderSize() {
  // get the X/Y cell counts for the two TTC types and insert in our table
  let x1 = document.getElementById("TTC-1001_under_x").textContent;
  let y1 = document.getElementById("TTC-1001_under_y").textContent;
  let x2 = document.getElementById("TTC-1002_under_x").textContent;
  let y2 = document.getElementById("TTC-1002_under_y").textContent;
  setInputsTriggerUpdate(x1, y1, x2, y2);
}

class TTCCalcStep2 extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    cellCount1001:{x:0, y:0},
    cellCount1002:{x:0, y:0}
  };

  handle1XChange = evt => {
    let cells = this.state.cellCount1001;
    cells.x = Number(evt.target.value);
    this.setState(cells);
  }
  
  handle1YChange = evt => {
    let cells = this.state.cellCount1001;
    cells.y = Number(evt.target.value);
    this.setState(cells);
  }
  
  handle2XChange = evt => {
    let cells = this.state.cellCount1002;
    cells.x = Number(evt.target.value);
    this.setState(cells);  
  }
  
  handle2YChange = evt => {
    let cells = this.state.cellCount1002;
    cells.y = Number(evt.target.value);
    this.setState(cells);
  }
  
  render() {
    return (
      <div className="TTCCalcStep2">
        <div className="ttcStepAndText">
          <h3 className="ttcStepLabel">Step 2: Attributes</h3>
          <div className="ttcStepDescription">Enter the number of cells in each direction. Click the buttons below to fill the fields with the Over Size or Under Size cell counts from Step 1.</div>
        </div>

        <div><button className="getCellCount" onClick={useOverSize}>Use Over Size</button><button className="getCellCount" onClick={useUnderSize}>Use Under Size</button></div>

        <table id="ttcCalcStep2">
          <TTCCalcStep2Header />
          <TTCCalcStep2Body
            cellCount1001={this.state.cellCount1001}
            cellCount1002={this.state.cellCount1002}
            ttcData={this.props.ttcData}
            cellSpacing={this.props.cellSpacing} 
            handle1XChange={this.handle1XChange}
            handle1YChange={this.handle1YChange}
            handle2XChange={this.handle2XChange}
            handle2YChange={this.handle2YChange}
            />
        </table>
      </div>
    );
  }
}

export default TTCCalcStep2;
