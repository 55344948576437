import './App.css';
import { Component } from 'react';


function TTCCalcStep1Header() {
  return (
    <thead>
      <tr>
        <th colSpan="2">Application Chip</th>
        <th className="leftSep" colSpan="3">Over Size</th>
        <th className="leftSep" colSpan="3">Under Size</th>
      </tr>
      <tr>
        <th>TTC Version</th>
        <th>&nbsp;</th>
        <th className="leftSep"># Cells</th>
        <th>Dimension (mm)</th>
        <th>Over Difference</th>
        <th className="leftSep"># Cells</th>
        <th>Dimension (mm)</th>
        <th>Under Difference</th>
      </tr>
    </thead>
  );
}

function TTCCalcStep1Row(props) {
  const { rowSpan, xyLabel, ttcVersion, cellCountOver, dimensionOver, percentOver, cellCountUnder, dimensionUnder, percentUnder } = props;

  let overID = ttcVersion + "_over_" + (xyLabel == "X" ? "x" : "y");
  let underID = ttcVersion + "_under_" + (xyLabel == "X" ? "x" : "y");
  if (rowSpan > 1) {
    return <tr>
            <td rowSpan={rowSpan}>{ttcVersion}</td>
            <td>{xyLabel}</td>
            <td className="leftSep" id={overID}>{cellCountOver}</td>
            <td>{dimensionOver}</td>
            <td>{percentOver}</td>
            <td className="leftSep" id={underID}>{cellCountUnder}</td>
            <td>{dimensionUnder}</td>
            <td>{percentUnder}</td>
           </tr>;
  } else {
    return <tr>
            <td>{xyLabel}</td>
            <td className="leftSep" id={overID}>{cellCountOver}</td>
            <td>{dimensionOver}</td>
            <td>{percentOver}</td>
            <td className="leftSep" id={underID}>{cellCountUnder}</td>
            <td>{dimensionUnder}</td>
            <td>{percentUnder}</td>
           </tr>;
  }
}

function TTCCalcStep1Body(props) {
  const { chipDimensions, ttcData, cellSpacing } = props;

  const rows = [];
  props.ttcData.forEach((element) => {
    let cellUnitSize = element.cellSize;
    if (!element.scribeLaneIncluded) {
      cellUnitSize += props.cellSpacing;
    }
    let xrowkey = "ttc_step1_rowx_" + cellUnitSize;
    let yrowkey = "ttc_step1_rowy_" + cellUnitSize;
    // row for x
    if (props.chipDimensions.x > 0) {
      let xCellCountOver = Math.floor(props.chipDimensions.x / cellUnitSize) + 1;
      let xDimensionOver = xCellCountOver * cellUnitSize - props.cellSpacing;
      xDimensionOver = xDimensionOver.toFixed(3);
      let xPercentOver = (xDimensionOver - props.chipDimensions.x) / props.chipDimensions.x;
      xPercentOver = (xPercentOver * 100).toFixed(2) + "%";
      let xCellCountUnder = xCellCountOver - 1;
      let xDimensionUnder = xCellCountUnder * cellUnitSize - props.cellSpacing;
      xDimensionUnder = xDimensionUnder.toFixed(3);
      let xPercentUnder = (xDimensionUnder - props.chipDimensions.x) / props.chipDimensions.x;
      xPercentUnder = (xPercentUnder * 100).toFixed(2) + "%";
      rows.push(<TTCCalcStep1Row key={xrowkey}
                              rowSpan="2" 
                              xyLabel="X"
                              ttcVersion={element.ttcVersion} 
                              cellCountOver={xCellCountOver}
                              dimensionOver={xDimensionOver}
                              percentOver={xPercentOver}
                              cellCountUnder={xCellCountUnder}
                              dimensionUnder={xDimensionUnder}
                              percentUnder={xPercentUnder} />);
    } else {
      rows.push(<TTCCalcStep1Row key={xrowkey}
                              rowSpan="2" 
                              xyLabel="X"
                              ttcVersion={element.ttcVersion}
                              cellCountOver="&nbsp;"
                              dimensionOver="&nbsp;"
                              percentOver="&nbsp;"
                              cellCountUnder="&nbsp;"
                              dimensionUnder="&nbsp;"
                              percentUnder="&nbsp;" />);
    }
    // row for y      
    if (props.chipDimensions.y > 0) {
      let yCellCountOver = Math.floor(props.chipDimensions.y / cellUnitSize) + 1;
      let yDimensionOver = yCellCountOver * cellUnitSize - props.cellSpacing;
      yDimensionOver = yDimensionOver.toFixed(3);
      let yPercentOver = (yDimensionOver - props.chipDimensions.y) / props.chipDimensions.y;
      yPercentOver = (yPercentOver * 100).toFixed(2) + "%";
      let yCellCountUnder = yCellCountOver - 1;
      let yDimensionUnder = yCellCountUnder * cellUnitSize - props.cellSpacing;
      yDimensionUnder = yDimensionUnder.toFixed(3);
      let yPercentUnder = (yDimensionUnder - props.chipDimensions.y) / props.chipDimensions.y;
      yPercentUnder = (yPercentUnder * 100).toFixed(2) + "%";
      rows.push(<TTCCalcStep1Row key={yrowkey}
                              rowSpan="1" 
                              xyLabel="Y"
                              ttcVersion={element.ttcVersion}
                              cellCountOver={yCellCountOver}
                              dimensionOver={yDimensionOver}
                              percentOver={yPercentOver}
                              cellCountUnder={yCellCountUnder}
                              dimensionUnder={yDimensionUnder}
                              percentUnder={yPercentUnder} />);
    } else {
      rows.push(<TTCCalcStep1Row key={yrowkey}
                              rowSpan="1" 
                              xyLabel="Y"
                              ttcVersion={element.ttcVersion}
                              cellCountOver="&nbsp;"
                              dimensionOver="&nbsp;"
                              percentOver="&nbsp;"
                              cellCountUnder="&nbsp;"
                              dimensionUnder="&nbsp;"
                              percentUnder="&nbsp;" />);
    }
  });
  return <tbody>{rows}</tbody>;
}

function TTCCalcStep1(props) {
  const { chipDimensions, ttcData, cellSpacing, handleXChange, handleYChange } = props;

  return (
    <div className="TTCCalcStep1">
      <div className="ttcStepAndText">
        <h3 className="ttcStepLabel">Step 1: Size</h3>
        <div className="ttcStepDescription">Enter the size of the semiconductor chip you are attempting to thermally simulate in the Application Chip Size boxes.</div>
      </div>

      <header className="TTCCalc-header">
        <p>
          Application Chip Size
        </p>
      </header>
      <div>
        X:&nbsp;<input className="sizeInput editableInput" id="xInput" name="xInput" value={chipDimensions.x} onChange={handleXChange}></input>&nbsp;mm
      </div>
      <div>
        Y:&nbsp;<input className="sizeInput editableInput" id="yInput" name="yInput" value={chipDimensions.y} onChange={handleYChange}></input>&nbsp;mm
      </div>
      <table id="ttcCalcStep1">
        <TTCCalcStep1Header />
        <TTCCalcStep1Body
          chipDimensions={chipDimensions}
          ttcData={ttcData}
          cellSpacing={cellSpacing} />
      </table>
    </div>
  );
}

export default TTCCalcStep1;
