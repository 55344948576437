import './App.css';
import { Component } from 'react';


class TTCCalcStep3 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="TTCCalcStep3">
        <div className="ttcStepAndText">
          <h3 className="ttcStepLabel">Step 3: Orientation</h3>
          <div className="ttcStepDescription">Oo maximize Pdiss while observing the TTC's max voltage and current limitations, it may be necessary to orient the Unit Cell array to position the HR contacts to optimize the resistor circuitry. With the Heat Resistors in the North/South direction, the number of columns is the X value, and the number of rows is the Y value in the form of XXYY.</div>
        </div>
  
        <div className="ttc1001OrientationImage"></div>
        <div className="ttcCaption">TTC-1001 Thermal Test Chip Array Designation Definition</div>

        <div className="ttc1002OrientationImage"></div>
        <div className="ttcCaption">TTC-1002 Thermal Test Chip Array Designation Definition</div>
        
      </div>
    );
  }

}

export default TTCCalcStep3;
