import './App.css';
import { Component } from 'react';


class TTCCalcStep4 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="TTCCalcStep4">
        <div className="ttcStepAndText">
          <h3 className="ttcStepLabel">Step 4: Part Number</h3>
          <div className="ttcStepDescription">Once TTV version, Unit Cell array size, and Array Orientation have been determined, the next step is to determine the chip or wafer Part Number. The Part Number has seven components to fully define the chip or wafer.</div>
        </div>
  
        <div className="ttcProductNumbersImage"></div>
        <div className="ttcCaption">Thermal Test Chip Wafer and Chip Product Numbers</div>
        
      </div>
    );
  }

}

export default TTCCalcStep4;
