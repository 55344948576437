import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import TTCCalc from './TTCCalc';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          Thermal Test Chip Calculator
        </header>
        <TTCCalc />
      </div>
    )
  }
}

export default App;
