import './App.css';
import React, {Component} from 'react';
import TTCCalcStep1 from './TTCCalcStep1';
import TTCCalcStep2 from './TTCCalcStep2';
import TTCCalcStep3 from './TTCCalcStep3';
import TTCCalcStep4 from './TTCCalcStep4';

class TTCCalc extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    chipDimensions:{x:0, y:0},
    cellSpacing:0.076,
    ttcData:[
      {ttcVersion:"TTC-1001", cellSize:1.00, scribeLaneIncluded:false},
      {ttcVersion:"TTC-1002", cellSize:2.54, scribeLaneIncluded:true}
    ]
  };

  handleXChange = evt => {
    let dimens = this.state.chipDimensions;
    dimens.x = Number(evt.target.value);
    this.setState(dimens);
  }

  handleYChange= evt => {
    let dimens = this.state.chipDimensions;
    dimens.y = Number(evt.target.value);
    this.setState(dimens);
  }

  render() {
    return (
      <div className="TTCCalc">

        <TTCCalcStep1
          chipDimensions={this.state.chipDimensions}
          cellSpacing={this.state.cellSpacing}
          ttcData={this.state.ttcData}
          handleXChange={this.handleXChange}
          handleYChange={this.handleYChange}
        />
        <TTCCalcStep2
          chipDimensions={this.state.chipDimensions}
          cellSpacing={this.state.cellSpacing}
          ttcData={this.state.ttcData}
          handleXChange={this.handleXChange}
          handleYChange={this.handleYChange}
        />
        <TTCCalcStep3/>
        <TTCCalcStep4/>

      </div>
    )
  }
}

export default TTCCalc;
